<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8 px-4">
          <h2 class="doc-title text-center">FUSE.GOLD LIMITED</h2>
          <h2 class="doc-title text-center"> TOKEN PURCHASE TERMS <br> AND CONDITIONS </h2>
          <p class="doc-pera">
THESE TERMS AND CONDITIONS (THE “​TERMS”) ARE THE TERMS AND CONDITIONS ON
WHICH FUSE.GOLD LIMITED (THE “COMPANY”) OFFER THE FUSE G TOKENS (DEFINED
BELOW) AND ON WHICH THE BUYER (THE “BUYER” AND/OR “YOU”) HEREBY
ACKNOWLEDGE AND ACCEPT THE TERMS ON WHICH THE FUSE G TOKENS ARE
OFFERED AND PURCHASED.</p>
          <p class="doc-pera">
PLEASE READ THESE TERMS CAREFULLY. BY PURCHASING FUSE G TOKENS FROM THE
COMPANY, YOU WILL BE BOUND BY THESE TERMS. THESE TERMS ARE TO BE READ
SUBJECT TO AND IN CONJUNCTION WITH THE WHITEPAPER (AS DEFINED BELOW).</p>
          <p class="doc-pera">
BY ACCEPTING THESE TERMS, YOU WILL BE ENTERING INTO A BINDING AGREEMENT
WITH THE COMPANY. THESE TERMS CONTAIN PROVISIONS WHICH AFFECT YOUR
LEGAL RIGHTS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT PURCHASE FUSE
G TOKENS AND NAVIGATE AWAY FROM THE WEBSITE (AS DEFINED BELOW).</p>
          <p class="doc-pera">
            
TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT AS OTHERWISE
SPECIFIED IN WRITING BY US:
          </p>
<p class="doc-pera"> <span class="pr-4"> A </span>
FUSE G TOKENS ARE SOLD ON AN “AS IS” AND “AS AVAILABLE” BASIS,
WITHOUT ANY WARRANTIES OR REPRESENTATIONS OF ANY KIND, AND WE
EXPRESSLY DISCLAIM ALL WARRANTIES AND REPRESENTATIONS RELATING TO
FUSE G TOKENS (WHETHER EXPRESS OR IMPLIED), INCLUDING, WITHOUT
LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT;
</p>
<p class="doc-pera"> <span class="pr-4"> B </span>
WE DO NOT REPRESENT OR WARRANT THAT FUSE G TOKENS ARE RELIABLE,
CURRENT OR DEFECT-FREE, MEET YOUR REQUIREMENTS, OR THAT ANY
DEFECTS WILL BE CORRECTED; AND
</p>
<p class="doc-pera"> <span class="pr-4"> C </span> WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT FUSE G TOKENS OR
THE DELIVERY MECHANISM FOR FUSE G TOKENS ARE FREE OF VIRUSES OR
OTHER HARMFUL COMPONENTS.</p>
<p class="doc-pera">NEITHER THESE TERMS NOR THE PROJECT DOCUMENTATION CONSTITUTE A
PROSPECTUS OR OFFERING DOCUMENT, AND ARE NOT AN OFFER TO SELL, NOR THE
SOLICITATION OF AN OFFER TO BUY ANY INVESTMENT OR FINANCIAL INSTRUMENT
IN ANY JURISDICTION. FUSE G TOKENS SHOULD NOT BE ACQUIRED FOR
SPECULATIVE OR INVESTMENT PURPOSES WITH THE EXPECTATION OF MAKING A
PROFIT ON IMMEDIATE OR FUTURE RE- SALE. </p>
<p class="doc-pera"> 
NO REGULATORY AUTHORITY HAS EXAMINED OR APPROVED ANY OF THE
INFORMATION SET OUT IN THESE TERMS AND/OR THE PROJECT DOCUMENTATION.
NO SUCH ACTION HAS BEEN OR WILL BE TAKEN UNDER THE LAWS, REGULATORY
REQUIREMENTS OR RULES OF ANY JURISDICTION. THE PUBLICATION,
DISTRIBUTION OR DISSEMINATION OF THESE TERMS AND/OR THE PROJECT
DOCUMENTATION DOES NOT IMPLY THAT APPLICABLE LAWS, REGULATORY
REQUIREMENTS OR RULES HAVE BEEN COMPLIED WITH. </p>
<ol>
  <li>
    <p class="doc-pera"> <b> The Company </b> </p>
    <p class="doc-pera"> <span class="pr-4"> 1.1 </span> 
    The Company is incorporated and registered in Gibraltar with registration
number 121092.
    </p>
    <p class="doc-pera"> <span class="pr-4"> 1.2 </span>The Company is the creator of the Tokens. </p>
    <p class="doc-pera"> <span class="pr-4"> 1.3 </span>The Company is willing to sell the Tokens subject to the Terms. </p>
    <p class="doc-pera"> <span class="pr-4"> 1.4 </span> The Company may be contacted via email at [-]. </p>
  </li>
  <li>
    <p class="doc-pera"> <b> Definitions </b>  </p>
    <p class="doc-pera"> <span class="pr-4"> 2.1 </span> In these Terms, the following words shall have the following definitions: </p>
    <p class="doc-pera"> <span class="pr-5"> <b> BTC </b> </span> means Bitcoin. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Buyer </b> </span> means a person who purchases the Tokens </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Data Controller </b> </span> means a “data controller” as defined in the Data Protection Act 2004. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Data Protection
Legislation </b> </span> 
means unless and until Regulation ((EU) 2016/679)
(or any national implementing laws) is directly
applicable, the Data Protection Act 2004 (as amended
from time to time. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Data Subject </b> </span> means a “data subject” as defined in the Data Protection Act 2004. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Ethereum </b> </span>
means the decentralized online platform that enables
smart contracts and distributed applications to be
built and run on a blockchain. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> ETH and/or Ether </b> </span> is the cryptocurrency whose blockchain is generated
by Ethereum. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Data Protection </b> </span> </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Insolvent </b> </span> means “insolvent” as defined in the Insolvency Act

2011. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Intellectual
Property
 </b> </span> 
means any and all patents, rights to inventions,
copyright and related rights, moral rights,
trademarks, business names and domain names,
rights in get-up and trade dress, goodwill and the
right to sue for passing off or unfair competition,
rights in designs, database rights, rights to use, and
protect the confidentiality of, confidential information
(including know-how and trade secrets), and all other
intellectual property rights, in each case whether
registered or unregistered and including all
applications and rights to apply for and be granted,
renewals or extensions of, and rights to claim priority
from, such rights and all similar or equivalent rights
or forms of protection which subsist or will subsist
now or in the future in any part of the world.</p>
    <p class="doc-pera"> <span class="pr-5"> <b> KYC </b> </span> means know your client and customer due diligence

 procedures in accordance with the Drugs Trafficking
Offences Act and the Proceeds of Crime Act 2015.
</p>
    <p class="doc-pera"> <span class="pr-5"> <b> Project
Documentation </b> </span> means the Website and the Whitepaper </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Purchase Price </b> </span> 
 means the price agreed between the Buyer and the
Company which includes the spot price for gold as
displayed on the Website at the time this Agreement
is made as well as any fees or commissions set forth
in the Whitepaper. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Tax </b> </span> 
means all forms of taxation, duties, imports, levies,
withholding, taxes, rates and charges of whatsoever
nature whether in Gibraltar or elsewhere in any part
of the world wherever or whenever, created or
imposed and includes (without limitation) corporation
tax, advance corporation tax, income tax, VAT,
customs and other import duties, excise duties, stamp
duty, stamp duty reserve tax, stamp duty land tax,
capital duty, social insurance contributions, foreign
taxation and any payment whatsoever which any
party may be or become bound to make to any
authority in any jurisdiction where he resides and/or
carries on business. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Third Party
Claims </b> </span> means the defined in clause 24. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Token </b> </span>  means the digital token generated and distributed by
the Company on the Ethereum ledger in accordance
with ERC-20 standard known as Tokens.
 </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Token
Distribution
 </b> </span> means the distribution of Tokens by the Company. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Token Holder </b> </span> means the holder of the Token. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Warranties
and/or Warranty
 </b> </span> means the warranties in schedule 1 to these Terms. </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Website </b> </span> means the Company’s website: fuse.gold </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Whitepaper </b> </span> means the whitepaper issued by the Company in
which the Company details the terms of each of its
Token Offerings and can be found on the Website.
 </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Data Protection </b> </span> </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Data Protection </b> </span> </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Data Protection </b> </span> </p>
    <p class="doc-pera"> <span class="pr-5"> <b> Data Protection </b> </span> </p>
    <p class="doc-pera"> <span class="pr-4"> 2.2 </span>Unless otherwise stated, all references to a legislative provision shall be
construed as including references to: </p>
<p class="doc-pera pl-3"> <span class="pr-3"> (a) </span> Legislative provisions in Gibraltar;</p>
<p class="doc-pera pl-3"> <span class="pr-3"> (b) </span> any legislative provision which modifies, consolidates, re-enacts or
supersedes it; </p>
<p class="doc-pera pl-3"> <span class="pr-3"> (c) </span> any subordinate and/or subsidiary legislation; </p>
<p class="doc-pera pl-3"> <span class="pr-3"> (d) </span> any legislative provisions of which it is a consolidation, re-enactment or
modification pursuant to it. </p>
    <p class="doc-pera"> <span class="pr-4"> 2.3 </span>Except where the context otherwise requires, words denoting the singular
include the plural and vice versa; references to a “person” include any
individual, firm, partnership, association, joint venture, state, agency of
state, unincorporated association or body corporate (whether or not having a
separate legal personality); references to any gender include all genders. </p>
    <p class="doc-pera"> <span class="pr-4"> 2.4 </span> Unless otherwise stated, a reference to a Clause, subClause or Schedule is a
reference to a clause or subclause of, or a schedule to, these Terms.</p>
    <p class="doc-pera"> <span class="pr-4"> 2.5 </span> Clause headings are for ease of reference only and shall not affect the
construction of these Terms. </p>
    <p class="doc-pera"> <span class="pr-4"> 2.6 </span> A reference to: </p>
<p class="doc-pera pl-3"> <span class="pr-3"> (a) </span> any Party includes its successors in title and permitted assigns; and</p>
<p class="doc-pera pl-3"> <span class="pr-3"> (b) </span> any provision of this agreement is to that provision as amended in
accordance with the terms of this agreement.</p>
<p class="doc-pera"> <span class="pr-4"> 2.7 </span> General words shall not be given a restrictive meaning:</p>
<p class="doc-pera pl-3"> <span class="pr-3"> (a) </span> if they are introduced by the word “other” by reason of the fact that
they are proceeded by words indicating a particular class of act, matter
or thing; or</p>
<p class="doc-pera pl-3"> <span class="pr-3"> (b) </span>by reason of the fact that they are followed by particular examples
intended to be embraced by those general words.</p>

    <p class="doc-pera"> <span class="pr-4"> 2.8 </span> The ejusdem generis rule shall not apply so that general words shall not be
given a restrictive interpretation by reason of their being preceded or
followed by words indicating a particular class of acts, matters, circumstances
or things. </p>
    <p class="doc-pera"> <span class="pr-4"> 2.9 </span> The Schedule forms part of these Terms and shall have effect as if set out in
the full body of these Terms; any reference to this agreement these Terms
includes the Schedule. </p>
    <p class="doc-pera"> <span class="pr-4"> 2.10 </span> Words such as “hereunder”, “hereto”, “hereof” and “herein” and other words
commencing with “here” shall unless the context clearly indicates to the
contrary refer to the whole of this agreement and not to any particular
section, clause or paragraph hereof. </p>
    <!-- <p class="doc-pera"> <span class="pr-4"> 2.3 </span> </p> -->
    <!-- <p class="doc-pera"> <span class="pr-4"> 2.3 </span> </p> -->
    <!-- <p class="doc-pera"> <span class="pr-4"> 2.3 </span> </p> -->
    <!-- <p class="doc-pera"> <span class="pr-4"> 2.3 </span> </p> -->
    <!-- <p class="doc-pera"> <span class="pr-4"> 2.3 </span> </p> -->
    <!-- <p class="doc-pera"> <span class="pr-4"> 2.3 </span> </p> -->

  </li>
   <li>
    <p class="doc-pera"> <b> Scope of Terms </b> These Terms (including any terms incorporated by reference) govern the terms of your
purchase of Tokens.  </p> 
    
    
    </li>
    <li>
    <p class="doc-pera"> <b> Token Price and allocation </b></p> 
    <p class="doc-pera"> <span class="pr-4"> 4.1 </span> The Buyer agrees to pay the Purchase Price and the Company shall allocate
and distribute the relevant number of Tokens to the Buyer, subject to the
Buyer completing the conditions set out in Clause 6. </p>
    <p class="doc-pera"> <span class="pr-4"> 4.2 </span> The Company in accepting the Purchase Price will - on the Buyers behalf –
purchase and arrange for the storage of the corresponding amount of gold as
set out in the Whitepaper. The Company will subsequently allocate any
purchased gold to corresponding Tokens held by the Buyer, subject to clause
7. </p>
    <p class="doc-pera"> <span class="pr-4"> 4.3 </span> The Company shall accept payment for Tokens in BTC or fiat currencies. The
Company may accept other forms of consideration at its sole and absolute
discretion. </p>
    <p class="doc-pera"> <span class="pr-4"> 4.4 </span> The Buyer shall provide the Company with a digital wallet address to which it
wants the Company to distribute the Tokens pursuant to this Agreement and
the Company shall, subject to clause 4.2, distribute the Tokens to the Buyer
within 7 Business Days. </p>
</li>
<li>
    <p class="doc-pera"> <b> Nature of Tokens </b></p> 
     <p class="doc-pera"> <span class="pr-4"> 5.1 </span> Other than set out in the Whitepaper, the purchase of the Tokens by the Buyer: </p>
     <p class="doc-pera"> shall not provide the Buyer with any rights in respect of the Company or its revenues, voting,
distribution, redemption;</p>
     <p class="doc-pera"> 
shall not be deemed to be a loan, security and/or other form of debt finance to the Company; </p>
     <p class="doc-pera"> shall not provide the Buyer with any equity, ownership (whether legal or beneficial) in the
Company; </p>
     <p class="doc-pera"> 
shall not entitle the Buyer to the provision of any goods or services by the Company; and/or </p>
     <p class="doc-pera"> shall not constitute any form of money or legal tender (or representation thereof) in any
jurisdiction; </p>
     <p class="doc-pera"> shall not constitute a “financial instrument” as defined in the Financial Services Act 2019. </p>
     <p class="doc-pera"> <span class="pr-4"> 5.2 </span> The Company shall retain all rights, title and interest in all of the Company’s Intellectual
Property, and the Buyer shall not use any of the Company’s Intellectual Property for any
reason without the Company’s prior written consent. </p>
     <!-- <p class="doc-pera"> </p> -->
     <p class="doc-pera"> <span class="pr-4"> 5.3 </span> 
     The Company makes no warranties or representations and offers no assurances (in each
case whether express or implied) that the Tokens shall confer any actual and/or exercisable
rights of use, functionality, features, purpose or attribute, including any warranty of
merchantability, warranty of fitness for a particular purpose, warranty of title or warranty
against infringement of intellectual property rights of a third party; whether arising by law,
course of dealing, course of performance, usage of trade, or otherwise, except as expressly
set forth herein and the Buyer acknowledges that it has not relied upon any representation
or warranty made by the Company, or any other person on the Company&#39;s behalf.
     </p>
    
    
    </li>
    <li>
    <p class="doc-pera"> <b> Buyer Warranties </b>
    The Buyer by purchasing the Tokens, hereby warrants and represents that each Warranty is
true, accurate and not misleading.
    </p> 
    </li>
    <li>
    <p class="doc-pera"> <b> Know your Client </b></p>
    <p class="doc-pera"> <span class="pr-4"> 7.1 </span> Before the Buyer is able to purchase the Tokens, the Company will require: </p> 
    <p class="doc-pera"> that the Buyer provide certain information and documentation for the purposes of complying with
any KYC, AML and source of funds obligations to which the Company is subject;  </p> 
    <p class="doc-pera"> 
request and obtain certain other information about the Buyer in order to comply with applicable
laws and regulations to which the Company is subject to in connection with the distribution of
Tokens to the Buyer; ​​and </p> 
    <p class="doc-pera"> 
assess the risk of the Ethereum wallet address (or addresses) provided by the Buyer. </p> 
<p class="doc-pera"> <span class="pr-4"> 7.2 </span> 
The Buyer is required to register and thereafter submit to the Company such information
and documentation as may be reasonably requested by the Company to:
</p>
<p class="doc-pera"> carry out, to the Company’s satisfaction, any KYC and/or any analogous checks; and </p>
<p class="doc-pera"> ensure, to the Company’s satisfaction, that the Company has complied with all applicable laws and
regulations in connection with the distribution of Tokens or as the Company may from time to time
require. </p>
<p class="doc-pera"> <span class="pr-4"> 7.3 </span> Once the KYC process has been approved the Buyer will be notified. If approved the Tokens
will be allocated in accordance with clause 4 of this Agreement.  </p>
<p class="doc-pera"> <span class="pr-4"> 7.4 </span> The Buyer acknowledges and accepts that the Company may refuse, reject or refund the
Purchase Price if the Buyer does not successfully pass the Company’s KYC process or the
Company determines that the Buyer falls out of the Company&#39;s risk appetite. Please note
that this is at the Company’s sole and absolute discretion.  </p>
    </li>
    <li>
      <p class="doc-pera"> <b> Audit of the Smart Contract System </b> </p>
      <p class="doc-pera"> <span class="pr-4"> 8.1 </span> The Company shall exercise reasonable endeavours to have the Smart
Contract System audited and approved by technical experts with regard to
both accuracy and security of the underlying code. </p>
      <p class="doc-pera"> <span class="pr-4"> 8.2 </span> Notwithstanding clause 8.1, smart contract technology is still in an early
stage of development and its application is currently of an experimental
nature, which carries significant operational, technological, financial,
regulatory and reputational risks. Accordingly, while any audit conducted
shall raise the level of security and accuracy of the Smart Contract System,
you acknowledge, understand and accept that the audit does not amount to
any form of warranty, representation or assurance (in each case whether
express or implied) that the Smart Contract System and [--]s are fit for a
particular purpose or that they are free from any defects, weaknesses,
vulnerabilities, viruses or bugs which could cause, inter alia, the complete
loss of your Tokens. </p>
    </li>
    <li>
      <p class="doc-pera"> <b> Security </b> </p>
      <p class="doc-pera">

You are responsible for implementing all reasonable and appropriate measures for
securing the Wallet you use to purchase and store the Tokens, including any requisite
private key(s) or other credentials necessary to access such storage mechanism(s). If
your private key(s) or other access credentials are lost, you may lose access to your
Tokens. The Company shall not be responsible for any security measures relating to your
receipt, possession, storage, transfer or potential future use of Tokens nor shall we be
under any obligation to recover or return any Tokens and we hereby exclude (to the
fullest extent permitted under applicable law) any and all liability for any security
breaches or other acts or omissions which result in your loss of (including your loss of
access to) Tokens issued to you during the Token Sale.
      </p>
       </li>
       <li>
      <p class="doc-pera"> <b> Risk Warning </b> </p>
      <p class="doc-pera">
        
You acknowledge and agree that purchasing the Token carries significant financial,
regulatory and reputational risks, including but not limited to those set out in the
Whitepaper.
      </p>
      <p class="doc-pera"> <b>
        BY PURCHASING THE TOKEN YOU ACCEPT THESE TERMS YOU EXPRESSLY
ACKNOWLEDGE, ACCEPT AND ASSUME THE RISKS SET OUT IN THE
WHITEPAPER.
      </b> </p>
       </li>
       <li>
      <p class="doc-pera"> <b> Indemnity </b> </p> 
      <p class="doc-pera"> <span class="pr-4"> 11.1 </span>To the fullest extent permitted by applicable law, you shall indemnify, defend
and hold harmless and reimburse the Company from and against any and all
claims, actions, proceedings, claims, damages, demands, actions, losses,
costs and expenses (including without limitation legal or other professional
associated costs), incurred by the Company arising from or in connection
with: </p>
      <p class="doc-pera pl-3"> <span class="pr-3"> (a) </span> your purchase or use of the Token;</p>
      <p class="doc-pera pl-3"> <span class="pr-3"> (b) </span> your responsibilities or obligations under these Terms;</p>
      <p class="doc-pera pl-3"> <span class="pr-3"> (c) </span> your breach of these Terms;</p>
      <p class="doc-pera pl-3"> <span class="pr-3"> (d) </span> any inaccuracy in any representation or Warranty by you;</p>
      <p class="doc-pera pl-3"> <span class="pr-3"> (e) </span>your breach of any rights of any other person or entity.
(together, “Third Party Claims”). </p>
      <p class="doc-pera"> <span class="pr-4"> 11.2 </span> The Buyer warrants that it shall delegate the conduct of any legal
proceedings (including any arbitration, negotiation or analogous proceedings)
in respect of the Third Party Claim to the Company. </p>
      </li>
      <li>
      <p class="doc-pera"> <b> Limitation of liability </b> </p> 
       <p class="doc-pera"> <span class="pr-4"> 12.1 </span>To the fullest extent permitted by applicable law, in no circumstances shall:</p>
      <p class="doc-pera pl-3"> <span class="pr-3"> (a) </span> the Company or any of its officers be liable for any direct, indirect,
special, incidental or consequential loss of any kind (including, but not
limited to, loss of revenue, income, business or profits, loss of contract
or depletion of goodwill, loss of anticipated savings, loss of use or data,
or damages for business interruption or any like loss) arising out of or in
any way related to the acquisition, storage, transfer or use of the
Tokens or otherwise related to these Terms, regardless of the cause of
action, whether based in contract, tort (including negligence), breach of
statutory duty, restitution or any other legal or equitable basis (even if
the Company or any of its officers have been advised of the possibility
of such losses and regardless of whether such losses were foreseeable);
and</p>
      <p class="doc-pera pl-3"> <span class="pr-3"> (b) </span> the aggregate liability of the Company and the Company’s officers
(jointly), whether in contract, tort (including negligence), breach of
statutory duty, restitution or any other legal or equitable basis, arising
out of or relating to these Terms or the use of or inability to use Tokens,
not exceed the amount equal to your Purchase Price.</p>
      <p class="doc-pera"> <span class="pr-4"> 12.2 </span> Nothing in this clause shall exclude or limit the liability of the Company for
dishonesty, fraud, willful misconduct or willful neglect by the Company. </p>
      </li>
      <li>
        <p class="doc-pera"><b> Taxation </b></p>
        <p class="doc-pera"> <span class="pr-4"> 13.1 </span> You are solely responsible for determining whether your purchase of the
Tokens will give rise to any Tax liability. </p>
        <p class="doc-pera"> <span class="pr-4"> 13.2 </span> You are also solely responsible for withholding, collecting, reporting, paying,
settling and/or remitting any and all Tax to the competent tax authorities in
the jurisdiction(s) in which you may be liable to pay tax. The Company shall
not be responsible for withholding, collecting, reporting, paying, settling
and/or remitting any Tax (including, but not limited to, any income, capital
gains, sales, value added or similar tax) which may arise from your
purchase and acquisition of the Tokens and/or in connection with these
Terms. </p>
        <p class="doc-pera"> <span class="pr-4"> 13.3 </span> You agree not to hold the Company or any of the Company’s officers liable for
any tax liability associated with or arising from the creation, ownership, use
or liquidation of Tokens or any other action or transaction related to the
Tokens Platform or the Token Sale. </p>
      </li>
      <li>
        <p class="doc-pera"><b> Data Protection </b></p>
        <p class="doc-pera"><b> IMPORTANT: The privacy notice, available at fuse.gold/privacy sets out the type of
information that the Company will collect, store and process in connection the Buyer’s
purchase via the Website. </b></p>
        <p class="doc-pera"><b> 
The Privacy Notice also sets out all of the rights that the Buyer has in connection with
the personal data the Company collects and stores and the Company’s obligations to
protect that personal data. </b></p>
        <p class="doc-pera"><b> 
The Buyer hereby confirms that it has read and understood the terms of the Company’s
Privacy Notice. </b></p>
<p class="doc-pera">
The Buyer acknowledges that, in collating the KYC information in accordance with clause 5 of this
Agreement, the Company may require the Buyer to provide information and documents relating
to:
</p>
<span class="doc-pera d-block"> the Buyer’s identity; </span>
<span class="doc-pera d-block">the Buyer’s residential and/or business address;</span>
<span class="doc-pera d-block">the source of the Buyer’s wealth and/or the funds used to make the Purchase;</span>
<span class="doc-pera d-block">any information associated with the Ethereum wallet used to make the Purchase; or</span>
<span class="doc-pera d-block">any other document from which the Buyer may be identified,</span>
<p class="doc-pera d-block">(the “Personal Data”).,</p>

<p class="doc-pera d-block">The Company will not disclose the Personal Data save as expressly permitted by this Agreement
and/or the Data Protection Legislation.</p>
<p class="doc-pera d-block">
The Company shall Process the Personal Data in accordance with the Data Protection Law and for
the purposes more specifically detailed in the privacy notice.</p>
<p class="doc-pera d-block">The Buyer warrants and represents that it has read and understood the privacy notice.
The Company will keep a record of any processing of Personal Data it carries out on behalf of the
Buyer.</p>
<p class="doc-pera d-block">
At the Buyer’s request, the Company shall provide the Buyer with a copy of all Personal Data held
by it in the format and on the media reasonably specified by the Buyer.
</p>
<p class="doc-pera d-block">
The Buyer acknowledges, understands, accepts, represents and warrants that, in accepting this
Agreement, the Buyer is irrevocably consenting to the ability of the Company to process and/or
transfer the Buyer’s Personal Data to another country within the European Economic Community.</p>
<p class="doc-pera d-block">
The Buyer acknowledges, accepts and understands that, if required and permitted under this
Agreement, the Company may transfer Personal Data to a country outside of the EEA on the basis
that the recipient affords the Personal Data an adequate level of protection and on the basis that
the Buyer has the same rights (and the ability to enforce those rights) in respect of the Buyer’s
Personal Data with the recipient.</p>
      </li>
      <li>
        <p class="doc-lera"><b>Dispute Resolution and Arbitration</b></p>
        <p class="doc-pera"> <span class="pr-4"> 15.1 </span> Any Dispute arising out from or in connection with the Agreement is personal to
the Buyer and the Buyer will not be able to engage with any third party for the
purposes of bringing a joint action against the Company with any other third
parties in connection with this Agreement. </p>
        <p class="doc-pera"> <span class="pr-4"> 15.2 </span> Any mediation and/or arbitration brought by any party in connection with the
Agreement shall take place in Gibraltar and shall be conducted in English. </p>
        <p class="doc-pera"> <span class="pr-4"> 15.3 </span> The arbitration shall be conducted by an independent third party. </p>
      </li>
      <li>
        <p class="doc-pera"><b> Notices </b></p>
        <p class="doc-pera"> <span class="pr-4"> 16.1 </span> Any notice or other communication given to a party under or in connection
with this agreement shall be in writing and shall be sent by email: </p>
      <p class="doc-pera pl-3"> <span class="pr-3"> (a) </span> in the case of the Company to the address [*];</p>
      <p class="doc-pera pl-3"> <span class="pr-3"> (a) </span> in the case of the Buyer the registered email address held on record by
the Company.</p>

        <p class="doc-pera"> <span class="pr-4"> 16.2 </span> A notice sent in accordance with clause 16.1 shall be deemed to have been
received at the time of transmission. </p>
        <p class="doc-pera"> <span class="pr-4"> 16.3 </span> This clause does not apply to the service of any proceedings or other
documents in any legal action or, where applicable, any arbitration or other
method of dispute resolution. </p>
      </li>
      <li>
        <p class="doc-pera"><b> Assignment </b></p>
        <p class="doc-pera"> Neither party may assign any of its rights or transfer any of its rights and obligations under
this agreement without the written consent of the other. </p>
      </li>
      <li>
        <p class="doc-pera"><b> Amendments </b></p>
        <p class="doc-pera">
The may amend the Agreement and Whitepaper from time to time, including where there are
changes to the intended functionality of the Token or as may be otherwise required by any laws or
regulatory requirements to which the Company is subject. If the Company makes any
amendments to the Agreement and Whitepaper, the Company will publish a notice together with
the updated documents on the Website and the Company will change the “Last Updated” date at
the top of the relevant documents. Any amended documents shall become effective immediately
upon the publication of such notice and updated documents on the Website.</p>
      </li>
      <li>
        <p class="doc-pera"><b> Severance </b></p>
        <p class="doc-pera">If any provision or part-provision of this agreement is or becomes invalid, illegal or
unenforceable, it shall be deemed modified to the minimum extent necessary to make it
valid, legal and enforceable. If such modification is not possible, the relevant provision or
part-provision shall be deemed deleted. Any modification to or deletion of a provision or

part-provision under this clause shall not affect the validity and enforceability of the rest
of this agreement</p>
      </li>
      <li>
        <p class="doc-pera"><b> No Partnership or Agency </b></p>
        <p class="doc-pera">
          
The Parties confirm that they are acting on their own and that nothing in these Terms is
intended to, or shall be deemed to, establish any partnership or joint venture between
any of the parties, constitute any party the agent of another party, or authorise any
party to make or enter into any commitments for or on behalf of any other party.
        </p>
      </li>
      <li>
        <p class="doc-pera"><b>Language</b></p>
        <p class="doc-pera"> <span class="pr-4"> 21.1 </span> 
        These Terms and the Whitepaper have been drafted and presented in the English
language and the English version of these terms shall prevail over any
translation of the same.
        </p>
      </li>
        <li> <p class="doc-pera"><b>Rights and Remedies</b></p>
        <p class="doc-pera">The rights and remedies provided for under these terms are in addition to and not
exclusive to any rights or remedies provided by law.</p>
        </li>
        <li> <p class="doc-pera"><b>Entire Agreement</b></p>
        <p class="doc-pera"> <span class="pr-4"> 23.1 </span> This Agreement constitutes the entire agreement between the parties and
supersedes and extinguishes all previous agreements, promises, assurances,
warranties, representations and understandings between them, whether written or
oral, relating to its subject matter.</p>
        <p class="doc-pera"> <span class="pr-4"> 23.2 </span>Each party agrees that it shall have no remedies in respect of any statement,
representation, assurance or warranty (whether made innocently or negligently)
that is not set out in this Agreement. </p>

        </li>
        <li> <p class="doc-pera"><b>Termination</b></p>
        <p class="doc-pera"> This Agreement will terminate on the issuance of Tokens to the Buyer pursuant to this
agreement. </p>
        </li>
        <li> <p class="doc-pera"><b>Force Majeure</b></p>
        <p class="doc-pera">
          
Neither Party shall be in breach of these Terms nor liable for delay in performing, or
failure to perform, any of their respective obligations under these Terms if such delay or
failure results from events, circumstances or causes beyond its reasonable control. In
such circumstances the affected party shall be entitled to a reasonable extension of the
time for performing such obligations.
        </p>
        </li>
        <li> <p class="doc-pera"><b>Third Party Rights</b></p>
        <p class="doc-pera"> This agreement does not give rise to any rights of third parties. </p>
        </li>
        <li> <p class="doc-pera"><b> Governing law </b></p>
        <p class="doc-pera">
          
These Terms, the Whitepaper and any Dispute arising from or in connection with them
with it or its subject matter or formation shall be governed by and construed in
accordance with the law of Gibraltar and the Courts of Gibraltar shall have exclusive
jurisdiction to settle any dispute or claim arising out of or in connection with this
agreement or its subject matter or formation.
        </p>
        </li>
</ol>
          <h2 class="doc-title text-center">Schedule 1</h2>
          <h2 class="doc-title text-center">THE BUYERS REPRESENTATIONS AND WARRANTIES</h2>
          <p class="doc-pera"> In purchasing the Tokens, you irrevocably represent and warrant that: </p>
          <ol>
            <li><p class="doc-pera">You are over 18 years old;</p></li>
            <li><p class="doc-pera">you have read and understood these Terms;</p></li>
            <li><p class="doc-pera"> you have read and understood the Whitepaper (including, for the avoidance of
doubt, the risk warnings set out therein); </p></li>
            <li><p class="doc-pera">you have the necessary authority to accept and enter into these Terms and
perform the obligations contained herein;</p></li>
            <li><p class="doc-pera">the acceptance of these Terms and the entry into a binding agreement with the
Company shall not result in any breach of, be in conflict with, or constitute a
material default under:</p>
<p class="doc-pera pl-4"> <span class="pr-2">(a)</span> any provision of the Buyer’s constitutional documents (in the case of a body
corporate);</p>
<p class="doc-pera pl-4"> <span class="pr-2">(b)</span> any provision of any judgment, decree or order imposed on you by any court of
competent jurisdiction, governmental authority and/or regulatory authority;
and/or </p>
<p class="doc-pera pl-4"> <span class="pr-2">(c)</span> any material agreement, obligation, duty or commitment to which you are a
party or are bound;</p>
</li>
            <li><p class="doc-pera">you have sufficient understanding of the functionality, usage, storage,
transmission mechanisms and intricacies associated with cryptographic tokens,
token storage facilities (including digital token wallets), distributed ledger
technology, blockchain technology and analogous systems;</p></li>
            <li><p class="doc-pera">you have obtained sufficient information about the Company and functionality of
the Tokens to make an informed decision to purchase the Tokens;</p></li>
            <li><p class="doc-pera">You agree and accept that the Tokens will only be considered to be your Tokens
when it is in your compatible ERC20 wallet.</p></li>
            <li><p class="doc-pera">you understand that the Tokens only confer the right to take possession of the
corresponding amount of Gold stored by the Company on your behalf as more
particularly described in the Whitepaper, and that the Tokens does not confer any
other rights of any kind with respect to the Company, including, but not limited to,
any voting, distribution, redemption, liquidation, proprietary (including all forms of
intellectual property rights), or other financial or legal rights.</p></li>
            <li><p class="doc-pera"> you have not relied on any oral or written statements, warranties, assurances,
representations or undertakings which were or may have been made by or on
behalf of the Company in relation to the subject matter of these terms at any time.</p></li>
            <li><p class="doc-pera"> where the Buyer is an individual, you are at least 18 years of age and have
sufficient legal capacity to accept these Terms and to enter into a binding
agreement with the Company; </p></li>
            <li><p class="doc-pera"> where the Buyer is purchasing the Tokens is a corporate entity, such entity is duly
incorporated, registered and validly existing under the applicable laws of the
jurisdiction in which it is incorporated; </p></li>
            <li><p class="doc-pera"> where the Buyer is a body corporate, the individual(s) acting on its behalf have
been duly authorised in accordance with that body corporate’s constitutional
documents to accept these Terms and enter into a binding agreement with the
Company for and on behalf of the body corporate; </p></li>
            <li><p class="doc-pera"> the purchase of the Tokens is not derived from or related to any unlawful
activities;</p></li>
            <li><p class="doc-pera"> you shall not use the Tokens to finance, engage in, or otherwise support any
unlawful activities; </p></li>
            <li><p class="doc-pera">
              the purchase price shall be transferred to the Company from an Ethereum wallet
that:
            </p>
            <p class="doc-pera pl-4"> <span class="pr-2">(a)</span>is registered to the Buyer and that the Buyer is the owner of the private key of
the Ethereum wallet; and</p>
<p class="doc-pera pl-4"> <span class="pr-2">(b)</span>is not located in or that is not registered in the name of a person located in or
resident of any country or territory that has been designated by the Financial
Action Task Force as a “non-cooperative country or territory” (a “Prohibited
Jurisdiction”)​​;</p>
            </li>
            <li><p class="doc-pera"> Purchasing Tokens under these Terms is not unlawful or prohibited under the laws
of your jurisdiction or under the laws of any other jurisdiction to which you may be
subject and any purchase shall be made in full compliance with applicable laws; </p></li>
            <li><p class="doc-pera"> you are not a citizen of or resident or domiciled or, in the case of a body
corporate, incorporated or registered in a Prohibited Jurisdiction or making the
purchase of tokens for the purchase of the Tokens from a location in a
Prohibited Jurisdiction; </p></li>
            <li><p class="doc-pera"> you are not the subject of any sanctions administered or enforced by any
country, government or international authority nor are you resident or
established (in the case of a corporate entity) in a country or territory that is
the subject of a country-wide or territory-wide sanctions by any Government
and/or regulatory authority; </p></li>
            <li><p class="doc-pera">you will comply with any and all Tax obligations in your jurisdiction of domicile
and/or incorporation/registration (in the case of a body corporate);</p></li>
            <li><p class="doc-pera">you are not Insolvent.</p></li>
            <li><p class="doc-pera">you understand and accept the risks of participating in token generation events
relating to early stage blockchain start-up businesses and acknowledge that these
risks are substantial.</p></li>
          </ol>
        </div>
      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>


export default {
   components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),

   },
    name: "ICOTAC",
}
</script>

<style>

</style>